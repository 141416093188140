





























import { mapGetters, mapState } from 'vuex';
import SettingsRolesView from '@/views/SettingsViews/SettingsRolesView.vue';
import SettingsLanguagesView from '@/views/SettingsViews/SettingsLanguagesView.vue';
import SettingsCompanyInfo from '@/views/SettingsViews/SettingsCompanyInfo.vue';
import FeatureFlagsView from '@/views/FeatureFlagsView/FeatureFlagsView.vue';
import WidgetSocialMedia from '@/components/widget-settings/WidgetSocialMedia.vue';
import CurrencySelectView from '@/views/SettingsViews/CurrencySelectView.vue';
import SettingsEventsView from '@/views/SettingsViews/SettingsEventsView.vue';
import SettingsCopyStakePackageView from '@/views/SettingsViews/SettingsCopyStakePackageView.vue';
import VTabsHash from '@/components/VTabsHash.vue';

interface TabItem {
  id: string;
  visible: boolean;
  text: string;
  component: string;
}

export default {
  name: 'SettingsView',
  components: {
    VTabsHash,
    SettingsCompanyInfo,
    WidgetSocialMedia,
    SettingsRolesView,
    SettingsLanguagesView,
    CurrencySelectView,
    FeatureFlagsView,
    SettingsEventsView,
    SettingsCopyStakePackageView
  },
  data(): any {
    return {
      tab: 0
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['isMobile', 'operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    tabs(): TabItem[] | [] {
      if (!this.operatorId) return [];

      return [
        {
          id: 'company-name',
          text: 'Company Info',
          visible: this.isSuperAdmin,
          component: 'SettingsCompanyInfo'
        },
        {
          id: 'roles',
          text: 'Roles Operator',
          visible:
            this.$role.is([
              'account',
              'admin',
              'copystake-account',
              'copystake-admin'
            ]) || this.isSuperAdmin,
          component: 'SettingsRolesView'
        },
        {
          id: 'languages',
          text: 'Languages',
          visible:
            (!this.isMobile && this.$role.can.update('settings language')) ||
            this.isSuperAdmin,
          component: 'SettingsLanguagesView'
        },
        {
          id: 'feature-management',
          text: 'Feature Management',
          visible:
            (!this.isMobile && this.$role.can.update('settings feature')) ||
            this.isSuperAdmin,
          component: 'FeatureFlagsView'
        },
        {
          id: 'events',
          text: 'Events',
          visible: !this.isMobile && this.$role.can.update('settings event'),
          component: 'SettingsEventsView'
        },
        {
          id: 'social-media',
          text: 'Social Media',
          visible:
            (!this.isMobile && this.$role.can.update('settings social')) ||
            this.isSuperAdmin,
          component: 'WidgetSocialMedia'
        },
        {
          id: 'currency',
          text: 'Currency',
          visible:
            this.isSuperAdmin || this.$role.can.update('settings currency'),
          component: 'CurrencySelectView'
        },
        {
          id: 'copystake-package',
          text: 'CopyStake Package',
          visible: this.isSuperAdmin,
          component: 'SettingsCopyStakePackageView'
        }
      ].filter((item: TabItem) => item.visible ?? true);
    }
  }
};
